<template>
  <Layout>
    <PageHeader :title="title" class="poppins fw-normal" />
    <div class="addUser">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="card">
            <div class="card-body">
              <form ref="form" @submit.prevent="submit">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Firstname-input"
                        >Feature Title</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="formrow-Firstname-input"
                        v-model.trim="$v.form.feature.$model"
                        placeholder="Enter Feature Name"
                      />
                      <div class="error" v-if="$v.form.feature.$error">
                        Title is required
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Firstname-input"
                        >Feature Icon</label
                      >
                      <input
                        type="file"
                        class="form-control"
                        accept="image/*"
                        @change="onFilebanner"
                ref="icon"
                        id="formrow-Firstname-input"
                      />
                     
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-wrap gap-3 mt-3">
                  <SubmitButton
                    :processing="processing"
                    type="submit"
                    class="btn btn-primary waves-effect waves-light w-md"
                    text="Add Feature"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
  <script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import { required } from "vuelidate/lib/validators";
export default {
  name: "add-category",
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      processing: false,
      title: "Add Feature",
      icon:null,
      form: {
        feature: null,
      },
    };
  },
  validations: {
    form: {
      feature: {
        required,
      },
    },
  },
  methods: {
    submit() {
      console.log(this.form);
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.processing = true;
        let formData = new FormData();
        formData.append('icon',this.icon);
        formData.append('form',JSON.stringify(this.form));
        this.$axios
          .post("feature", formData)
          .then((response) => {
            this.$router.push({ path: "/features/listing" });
            this.triggerSwal(response.data.message, "success");
            // console.log(response.data);
            this.$refs.form.reset();
            this.processing = false;
          })
          .catch((error) => {
            // console.log(error.response.data.message);
            this.handleErrorResponse(error.response, "error");
            this.processing = false;
          });
      }
    },
    onFilebanner(e) {
      this.icon = e.target.files[0];
      // this.icon = this.$refs.icon.files[0];
      // this.banner_url = URL.createObjectURL(file);
    },
  },
};
</script>